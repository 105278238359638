import { Link } from 'gatsby';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Styles from './categories.module.scss';
import Img from 'gatsby-image';

export default ({ categories, heading }) => (
    <Container className="my-5 py-md-5">
        <Row className="mb-5">
            <Col className="text-center">
                <h2 className="text-pink font-weight-bold">{heading}</h2>
            </Col>
        </Row>
        <Row>
            {categories.map((cat, index) => (
                <Col key={index} xs="12" lg="4" className={`position-relative text-center mb-5 mb-md-0`}>
                    <div className={`${Styles.hoverBox} position-relative`}>
                        <Img className="w-100" fluid={cat.image.fluid}/>
                        <div className={Styles.linkBox}>
                            <Link className="button-link unstyled-link" to={cat.link}>{cat.name}</Link>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    </Container>
);
