import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/layout';
import Categories from '../components/home/categories/categories';
import Divider from '../components/home/divider/divider';
import Intro from '../components/home/intro/intro';
import LandingHeader from '../components/global/landing-header/landing-header';
import RecentPosts from '../components/global/recent-posts/recent-posts';
import MailchimpSignup from '../components/global/mailchimp-signup/mailchimp-signup';

export default function ({ data: { allPrismicBlogPost: posts, image_abnehmen, image_motivation, image_laufen, image_calvin_1_sw, image_calvin_2_sw, image_calvin_3_sw } }) {

  return (
    <Layout footerPosts={posts} manualTitleControl={true} title="Mindfoe | Dein Blog für Veränderung" description="Lifestyle verändern, abnehmen und glücklich werden! Wie du das erreichst, erfährst du hier.">
      <LandingHeader
        text="Lifestyle verändern, abnehmen und glücklich werden!"
        links={[
          { text: 'Neueste', link: `/${posts.nodes[0].uid}`, title: posts.nodes[0].data.title.text },
          { text: 'Abnehmen', link: '/blog/abnehmen' },
          { text: 'Persönlichkeitsentwicklung', link: '/blog/persoenlichkeitsentwicklung' },
          { text: 'Laufen', link: '/blog/laufen' },
        ]}
        fullHeight={true}
        backgroundImage="'/images/mindfoe.svg'"
      ></LandingHeader>
      <div className="mb-5">
        <RecentPosts posts={posts} blackWhiteImages={true}></RecentPosts>
      </div>
      <MailchimpSignup alwaysVertical={false} outerClass="py-5" slice={{
        primary: {
          title1: {
            text: '5 super Tipps, um mit dem Abnehmen zu beginnen!'
          },
          text: {
            html: '<p>Zwei Seiten, gefüllt mit 5 nützlichen Tipps & Tricks, damit Du mit dem Abnehmen beginnen kannst! Trage dich jetzt ein und erhalte die PDF in wenigen Minuten. Auch super zum Ausdrucken geeignet!</p>'
          }
        }
      }} />
      <Intro
        heading="Über Mindfoe"
        images={[
          { fluid: image_calvin_1_sw.childImageSharp.fluid },
          { fluid: image_calvin_2_sw.childImageSharp.fluid },
          { fluid: image_calvin_3_sw.childImageSharp.fluid }
        ]}
        paragraphs={[
          'Hi! Mein Name ist Calvin Sievers und ich bin das Köpfchen hinter dieser Website!',
          'Vor eine Weile habe ich festgestellt, dass ich mit meinem Leben nicht mehr glücklich bin. Ich verbrachte unzählige Stunden nur damit, Serien auf Netflix zu gucken oder in der virtuellen Welt zu versinken.',
          '<strong>Damit kann man auch nicht glücklich werden…</strong> Hab ich damals nicht gemerkt.',
          'Ende 2018 habe ich damit begonnen, mein Leben umzukrempeln. Ganz klischeehaft habe ich zum 01.01.2019 meinen Computer im Keller verstaut und habe ihn seitdem nicht mehr angefasst! (Ich benutze mein MacBook zum Schreiben!)',
          'Kurz danach, auf der Suche nach neuen Hobbies, bin ich aufs Laufen gestoßen. Ich hab mich sofort verliebt. <strong>Das war der Anfang eines neuen Kapitels.</strong>',
          'Ich habe noch einen sehr langen Weg vor mir, aber ich habe die nötige Motivation gefunden und möchte dies nun auch teilen – um anderen Leuten, die in meiner Lage sind, helfen zu können.'
        ]}>
      </Intro>
      <Divider></Divider>
      <Categories
        categories={[
          { name: 'Abnehmen', image: { fluid: image_abnehmen.childImageSharp.fluid }, link: 'blog/abnehmen' },
          { name: 'Persönlichkeitsentwicklung', image: { fluid: image_motivation.childImageSharp.fluid }, link: 'blog/persoenlichkeitsentwicklung' },
          { name: 'Laufen', image: { fluid: image_laufen.childImageSharp.fluid }, link: 'blog/laufen' },
        ]}
        heading="Kategorien"
      ></Categories>
    </Layout>
  );
}

export const pageQuery = graphql`
  query LatestPosts {
    image_abnehmen: file(name: {eq: "abnehmen"}, extension: {eq: "jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_motivation: file(name: {eq: "motivation"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_laufen: file(name: {eq: "laufen"}, extension: {eq: "jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_calvin_1_sw: file(name: {eq: "calvin-1-sw"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_calvin_2_sw: file(name: {eq: "calvin-2-sw"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_calvin_3_sw: file(name: {eq: "calvin-3-sw"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allPrismicBlogPost(limit: 5, sort: {fields: first_publication_date, order: DESC}) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          title_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        first_publication_date_from_now: first_publication_date(fromNow: true)
        first_publication_date_time: first_publication_date(formatString: "hh:mm")
        first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
        first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
        tags
      }
    }
  }
`
