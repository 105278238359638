import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default () => (
    <Container className="my-3 my-md-5 py-md-4">
      <Row>
        <Col>
            <hr style={{ width: '80%' }}/>
        </Col>
      </Row>
    </Container>
);
