import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { convertTagForUrl } from '../../../utils/text';
import Styles from './recent-posts.module.scss';

const TagList = ({tags}) => (
    <ul className="list-unstyled m-0">
        {tags.map((tag, index) => {
            return <li key={index} className={`${Styles.tag}`}><Link className="unstyled-link" to={`/blog/${convertTagForUrl(tag)}`}>{tag.toLowerCase()}</Link></li>
        })}
    </ul>
);

const ReadMoreLink = ({ uid }) => (
    <Link className={`${Styles.readMoreLink}`} to={`/${uid}`}>Mehr lesen</Link>
);

const PublishTime = ({post}) => (
    <small className={`mb-1 ${Styles.publishTime}`}>Veröffentlicht am <span title={post.first_publication_date_day + ', ' + post.first_publication_date + ' um ' + post.first_publication_date_time + ' Uhr'}>{post.first_publication_date}</span></small>
);

const PostMetaSection = ({post, showExcerpt, hideLatestPostCaption}) => (
    <React.Fragment>
        {showExcerpt && !hideLatestPostCaption ? (
            <span className="d-block font-weight-bold mb-2">Neuester Beitrag</span>
        ): ''}
        <Link to={`/${post.uid}`} className="unstyled-link">
            <h3 className={`font-weight-bold text-pink mb-2 ${Styles.postHeading}`}>{post.data.title.text}</h3>
        </Link>
        <PublishTime post={post}/>
        <TagList tags={post.tags}></TagList>
        {showExcerpt ? (
            <React.Fragment>
                <p className="mt-3">{post.data.excerpt.text}</p>
                <ReadMoreLink uid={post.uid}></ReadMoreLink>
            </React.Fragment>
        ) : ''}
    </React.Fragment>
);

export default ({ posts, blackWhiteImages, hideLatestPostCaption }) => {
    const latestPost = posts.nodes[0];
    return (
        <Container>
            {/* Latest Post */}
            <Row className="mb-lg-5">
                <Col xs="12" lg="6" className="mb-3 mb-lg-0">
                    <Link to={`/${latestPost.uid}`} className="unstyled-link">
                        <Img fluid={latestPost.data.title_image.fluid} className={`w-100 rounded-4px ${blackWhiteImages ? Styles.filterGreyscale : ''}`} alt={latestPost.data.title.text}/>
                    </Link>
                </Col>
                <Col className="mb-5 mb-lg-5" xs="12" lg="6">
                    <PostMetaSection hideLatestPostCaption={hideLatestPostCaption} showExcerpt={true} post={latestPost}/>
                </Col>
            </Row>

            {/* Older Posts */}
            <Row className="pt-lg-5">
                {posts.nodes.filter((post, index) => index > 0).map((post, index) => (
                    <Col key={index} xs="12" lg="6" className="mb-5 mb-lg-5">
                        <Link to={`/${post.uid}`} className="unstyled-link">
                            <BackgroundImage
                            Tag="div"
                            className={`${Styles.image} ${blackWhiteImages ? Styles.filterGreyscale : ''} rounded-4px mb-3`}
                            fluid={post.data.title_image.fluid}
                            >
                            </BackgroundImage>
                        </Link>
                        <PostMetaSection post={post}/>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
